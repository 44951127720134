<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center justify-space-between">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Vendor
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                  <div>
                    <!-- <v-btn
                      elevation="0"
                      dark
                      height="43"
                      @click="dialog_bulk_vendor = true"
                      class="
                        font-weight-bold
                        text-capitalize
                        ms-auto
                        btn-primary
                        bg-success
                        py-3
                        px-6
                        ms-0
                        mx-2
                      "
                      >Add multiple Vendor</v-btn
                    > -->
                    <v-dialog v-model="dialog" max-width="1000px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          dark
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            ms-auto
                            btn-primary
                            bg-success
                            py-3
                            px-6
                            ms-3
                          "
                          >New Vendor</v-btn
                        >
                      </template>
                      <v-card class="card-shadow border-radius-xl">
                        <v-form ref="frm" lazy-validation>
                          <div class="card-header-padding card-border-bottom">
                            <span
                              class="font-weight-bold text-h5 text-typo mb-0"
                              >{{ formTitle }}</span
                            >
                          </div>
                          <v-card-text class="card-padding">
                            <v-container class="px-0">
                              <v-row>
                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                    >Vendor</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.name"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[
                                      (v) => !!v || 'Vendor Name is required',
                                    ]"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Vendor Name"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                    >Vendor Code</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.vendor_code"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[
                                      (v) => !!v || 'Vendor Code is required',
                                    ]"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Vendor Code "
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Phone</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.phone"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[(v) => !!v || 'Phone is required']"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Phone"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Email</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.email"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[(v) => !!v || 'Email is required']"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Email"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    State</label
                                  >
                                  <v-select
                                    v-model="editedItem.state"
                                    :items="states"
                                    return-object
                                    :rules="[(v) => !!v || 'State is Required']"
                                    item-text="state_name"
                                    item-value="id"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      border-radius-md
                                      select-style
                                      mt-2
                                      mb-0
                                    "
                                    outlined
                                    single-line
                                    height="46"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Pan no</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.pan_no"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Pan no"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    TB reg no</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.tb_reg_no"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="TB reg no"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    CIN</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.cin"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="CIN"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    FSSAI license no</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.fssai_license_no"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="FSSAI license no"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    GST no</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.gst_no"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="gstRules"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="GST no"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Payment day limit</label
                                  >
                                  <v-text-field
                                    v-model.number="
                                      editedItem.payment_day_limit
                                    "
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[
                                      (v) =>
                                        !!v || 'Payment day limit is required',
                                      (v) => v >= 0 || 'Min should 0 or More',
                                    ]"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Payment day limit"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Address</label
                                  >
                                  <v-text-field
                                    v-model="editedItem.address"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="[
                                      (v) => !!v || 'Address is required',
                                    ]"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Address"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Vendor Type</label
                                  >
                                  <v-select
                                    v-model="editedItem.type"
                                    :items="vendorType"
                                    item-text="name"
                                    item-value="name"
                                    color="rgba(0,0,0,.6)"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      border-radius-md
                                      select-style
                                      mt-2
                                      mb-0
                                    "
                                    :rules="[
                                      (v) => !!v || 'Vendor Type is Required',
                                    ]"
                                    outlined
                                    single-line
                                    height="46"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col
                                  cols="4"
                                  v-if="
                                    editedItem.type == 'PRIVATE' ||
                                    editedItem.type == 'PRIVATE AND AUCTION'
                                  "
                                >
                                  <label
                                    class="
                                      text-md text-typo
                                      font-weight-bolder
                                      ms-1
                                    "
                                  >
                                    Password</label
                                  >
                                  <v-text-field
                                    :append-icon="
                                      show1 ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"
                                    v-model="editedItem.password"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-2
                                    "
                                    :rules="passwordRules"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="46"
                                    placeholder="Password"
                                    persistent-hint
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions
                            class="card-padding d-flex justify-end"
                          >
                            <v-btn
                              @click="close"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-secondary
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="save"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              dark
                              class="
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Save</v-btn
                            >
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.center`]="{ item }">
                    <v-chip
                      class="ma-2"
                      v-for="centers in item.auction_center"
                      :key="centers.id"
                    >
                      {{ centers.auction_center_name }}
                    </v-chip>
                  </template>
                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <BulkVendors
      :dialog_bulk_vendor="dialog_bulk_vendor"
      @close_dialog_bulk_vendor="closeDialogBulkVendor()"
    />
  </div>
</template>
<script>
import api from "../api";
import apiMaster from "../../master-get-api";
import BulkVendors from "./BulkVendors.vue";
import { paginationLimits } from "../../../../global-data/paginationLimits";

export default {
  name: "paginated-tables",
  components: {
    BulkVendors,
  },
  data() {
    return {
      show1: true,
      dialog_bulk_vendor: false,
      page: 1,
      pageCount: 0,
      paginationLimits: paginationLimits,
      itemsPerPage: 10,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => /([a-z])/.test(v) || `Atleast one Lower Case letter`,
        (v) => /([A-Z])/.test(v) || `Atleast one Upper Case letter`,
        (v) => /([0-9])/.test(v) || `Atleast one digit`,
        (v) =>
          /([$&+,:;=?@#|'<>.^*()%!-])/.test(v) ||
          `Atleast One Special Character`,
        (v) => v.length > 8 || "Be Atleast 8 characters",
      ],
      gstRules: [
        (v) => !!v || "GST no is required",
        // (v) =>
        //   /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/.test(
        //     v
        //   ) || "GST must be valid",
      ],
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",

      states: [],

      vendorType: [
        { name: "PRIVATE" },
        { name: "AUCTION" },
        { name: "PRIVATE AND AUCTION" },
        // { name: "OWN" },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        address: "",
        phone: "",
        email: "",
        pan_no: "",
        tb_reg_no: "",
        cin: "",
        fssai_license_no: "",
        gst_no: "",
        payment_day_limit: 0,
        state: null,
        vendor_code: "",
        type: "",
        password: "",
        id: 0,
      },
      defaultItem: {
        name: "",
        address: "",
        phone: "",
        email: "",
        pan_no: "",
        tb_reg_no: "",
        cin: "",
        fssai_license_no: "",
        gst_no: "",
        payment_day_limit: 0,
        state: null,
        vendor_code: "",
        type: "",
        password: "",
        id: 0,
      },
      headers: [
        {
          text: "Vendor Name",
          sortable: true,
          value: "name",
          width: "250",
        },
        {
          text: "Vendor code",
          sortable: true,
          width: "250",
          value: "vendor_code",
        },
        {
          text: "Phone",
          sortable: true,
          width: "250",
          value: "phone",
        },
        {
          text: "Email",
          sortable: true,
          width: "250",
          value: "email",
        },
        {
          text: "PAN",
          sortable: true,
          width: "250",
          value: "pan_no",
        },
        {
          text: "cin",
          sortable: true,
          width: "250",
          value: "cin",
        },
        {
          text: "fssai license no",
          sortable: true,
          width: "250",
          value: "fssai_license_no",
        },
        {
          text: "gst no",
          sortable: true,
          width: "250",
          value: "gst_no",
        },
        {
          text: "tb reg no",
          sortable: true,
          width: "200",
          value: "tb_reg_no",
        },
        { text: "Actions", value: "actions", width: "150", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    reset() {
      this.$refs.frm.reset();
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      this.items = await api.get();
      this.states = await apiMaster.getState();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.initialize();
        this.showSuccessAlert(`Vendor Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },
    closeDialogBulkVendor() {
      this.dialog_bulk_vendor = false;
      this.initialize();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
      });
    },

    async save() {
      if (this.validate()) {
        try {
          let type = "Saved";
          if (this.editedIndex > -1) {
            let data = {
              name: this.editedItem.name,
              address: this.editedItem.address,
              phone: this.editedItem.phone,
              email: this.editedItem.email,
              pan_no: this.editedItem.pan_no,
              tb_reg_no: this.editedItem.tb_reg_no,
              cin: this.editedItem.cin,
              fssai_license_no: this.editedItem.fssai_license_no,
              gst_no: this.editedItem.gst_no,
              payment_day_limit: this.editedItem.payment_day_limit,
              state: this.editedItem.state,
              vendor_code: this.editedItem.vendor_code,
              type: this.editedItem.type,
              password: this.editedItem.password,
            };
            type = "Updated";
            await api.edit(this.editedItem.id, data);
            Object.assign(this.items[this.editedIndex], this.editedItem);
          } else {
            let item = await api.create(this.editedItem);
            this.items.push(item);
          }
          this.showSuccessAlert(`Vendor ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Vendor   " : "Edit Vendor";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
